@tailwind base;
@tailwind components;
@tailwind utilities;
iframe#webpack-dev-server-client-overlay{display:none!important}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem; /* Base font size, usually 16px */
}

.title {
  font-size: 1.5rem; 
}

.subtitle {
  font-size: 1.25rem; /* 1.25 times the base font size */
}

.bold-text {
  font-size: 1.25rem; /* 1.25 times the base font size */
  font-weight: 700; /* Bold weight */
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  height: 1.5em; /* Adjust based on your font size to ensure one line */
  line-height: 1.5; /* Ensure this matches your font size line height */
}


/* Hide scrollbar by default */
.sidebar-custom-scrollbar {
  scrollbar-width: none; /* For Firefox */
}

.sidebar-custom-scrollbar::-webkit-scrollbar {
  width: 0; /* For Chrome, Safari, and Opera */
}

/* Show scrollbar on hover with custom colors */
.sidebar-custom-scrollbar:hover {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #3d3d3d #1a1a1a; /* Custom colors */
}

.sidebar-custom-scrollbar:hover::-webkit-scrollbar {
  width: 8px; /* Adjust width as needed */
}

.sidebar-custom-scrollbar:hover::-webkit-scrollbar-track {
  background: #1a1a1a; /* Dark grey background for the track */
}

.sidebar-custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #3d3d3d; /* Dark grey color for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #1a1a1a; /* Optional: Add some padding around the thumb */
}

.video-container {
  padding-bottom: 57.40%;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .video-container {
    padding-bottom: 75%; /* Increase the padding-bottom for a smaller aspect ratio */
  }
}

@media (max-width: 480px) {
  .video-container {
    padding-bottom: 100%; /* Make it a 1:1 aspect ratio */
  }
}
